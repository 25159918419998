<template>
	<div>
		<NavList v-show="showMenu" @close="showMenu = false" />
		<button type="button" class="header__btn-menu" :class="{ 'close': showMenu }" @click="toggleMenu" v-if="windowWidth < 1440"></button>
	</div>
</template>
<script>
import NavList from './NavList.vue';

export default {
	components: {
		NavList
	},
	data() {
		return {
			showMenu: true,
			windowWidth: window.innerWidth,
		}
	},
	methods: {
		toggleMenu() {
			this.showMenu = !this.showMenu;
			onClickClose(document.querySelector('.header__btn-menu'), this);

			function onClickClose(elem, vue) { // вызвать в момент показа окна, где elem - окно
			    function outsideClickListener(event) {
			        if (!elem.contains(event.target) && isVisible(elem)) {  // проверяем, что клик не по элементу и элемент виден
			             vue.showMenu = false;
			             document.removeEventListener('click', outsideClickListener);
			        }
			    }
			    document.addEventListener('click', outsideClickListener)
			}
			function isVisible(elem) { //открыто ли условное окно
			   return !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length );
			}
		}
	},
	created() {
		window.onresize = () => {
			this.windowWidth = window.innerWidth;
			this.showMenu = this.windowWidth < 1440 ? false : true;
		}

		this.showMenu = this.windowWidth < 1440 ? false : true;
	}
}
</script>
