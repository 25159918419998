<template>
  <button class="scrolltop" :class="{ hidden: !isVisible }" @click="scrollTop"></button>
</template>
<script>
export default {
  data() {
    return {
      winScrolltop: 0,
      winHeight: 0
    }
  },
  computed: {
    isVisible() {
      return this.winScrolltop > this.winHeight;
    }
  },
  methods: {
    scrollTop() {
      $('html, body').stop().animate({
        scrollTop: 0
      }, 500);
    }
  },
  mounted() {
    const _self = this;
    $(window).on('load, scroll', function() {
      _self.winScrolltop = $(this).scrollTop();
    }).on('resize', function() {
      _self.winHeight = $(this).innerHeight();
    });
  }
}
</script>
