<template>
    <div>
        <Header />
        <Scrolltop />
    </div>
</template>
<script>
    import Header from './components/HeaderNav.vue';
    import Scrolltop from './components/Scrolltop.vue';

    export default {
        components: {
            Header,
            Scrolltop
        },
    }
</script>
